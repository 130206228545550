import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AdminTalentViewComponent } from "./admin-talent-view.component";
import { TranslateModule } from "@ngx-translate/core";
import { AccountCommentsModule } from "../../../../../../../common-ui/src/lib/account-comments/account-comments.module";
import { AdminTalentDataspacesModule } from "../../../../common-componnets/admin-talent-dataspaces/admin-talent-dataspaces.module";
import { ProfilePreviewModule } from "../../../../common-componnets/profile-view/profile-preview.module";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzCardModule } from "ng-zorro-antd/card";
import { NzIconModule } from "ng-zorro-antd/icon";

@NgModule({
  declarations: [AdminTalentViewComponent],
    imports: [
      CommonModule,
      TranslateModule,
      AccountCommentsModule,
      AdminTalentDataspacesModule,
      ProfilePreviewModule,
      NzGridModule,
      NzCardModule,
      NzIconModule,
    ],
})
export class AdminTalentViewModule {}
