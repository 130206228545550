<ng-container *nzModalTitle>
  <div class="modal-header">
    <span>{{'job-offer-talent.comments.modal.title' | translate}}</span>
    <span>{{talent.salutation}} {{talent.firstName}} {{talent.lastName}}</span>
  </div>
</ng-container>

<mh-job-offer-talent-comments
  [comments]="comments"
  [scrollable]="true"
  (sendComment)="onSendComment($event)"
  (deleteComment)="onDeleteComment($event)"
></mh-job-offer-talent-comments>

