import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Talent } from "../../../../../../api/sign-up/signup.types";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable, Subject, takeUntil } from "rxjs";
import { CmsNameValue, compareCmsNameValue } from "../../../../../../api/cms/cms.types";
import { CmsCachedService } from "../../../../../../api/cms/cms-cached.service";
import { NzMarks } from "ng-zorro-antd/slider";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "mh-profile-desired-job",
  templateUrl: "./profile-desired-job.component.html",
  styleUrls: ["./profile-desired-job.component.less"],
})
export class ProfileDesiredJobComponent implements OnInit, OnDestroy {
  @Input() talent!: Talent;
  @Output() formChange = new EventEmitter<Talent>();
  @Output() submitChange = new EventEmitter<void>();

  public formGroup!: FormGroup;
  private readonly destroy$ = new Subject<void>();
  public developmentPath$: Observable<Array<CmsNameValue>>;
  public sector$: Observable<Array<CmsNameValue>>;
  public departmentOptions$: Observable<Array<CmsNameValue>>;
  public weekWorkingHoursOptions$: Observable<Array<CmsNameValue>>;
  public slotOptions$: Observable<Array<CmsNameValue>>;
  public workAssignmentOptions$: Observable<Array<CmsNameValue>>;
  public wishIndustry$: Observable<Array<CmsNameValue>>;
  public languageOptions$: Observable<Array<CmsNameValue>>;
  public companyTypeOptions$: Observable<Array<CmsNameValue>>;
  public leadershipResponsibility$: Observable<Array<CmsNameValue>>;
  public radiusOptions$: Observable<Array<CmsNameValue>>;

  marksReadiness: NzMarks = {
    1: this.translateService.instant("lowest.label"),
    5: this.translateService.instant("immediate.label"),
  };

  get travelWillingness(): string {
    return this.formGroup.controls['desiredPlaceOfWork'].get("travelWillingness")?.value || 1;
  }
  get companyTypeControl(): AbstractControl | null {
    return this.formGroup.controls['desiredJob'].get('desiredCompanyType');
  }
  get ownCompanyTypeControl(): AbstractControl | null {
    return this.formGroup.controls['desiredJob'].get('desiredCompanyTypeText');
  }
  get idealWorkingLocationDistanceControl(): AbstractControl | null {
    return this.formGroup.controls['desiredJob'].get('idealWorkingLocationDistance');
  }
  get idealWorkingLocationControl(): AbstractControl | null {
    return this.formGroup.controls['desiredPlaceOfWork'].get('idealWorkingLocation');
  }

  constructor(
    private cmsCachedService: CmsCachedService,
    private fb: FormBuilder,
    private readonly translateService: TranslateService,
  ) {
    this.developmentPath$ = this.cmsCachedService.getUserDataByName("development-path", false);
    this.sector$ = this.cmsCachedService.getUserDataByName("sector");
    this.departmentOptions$ = this.cmsCachedService.getUserDataByName("expert-field");
    this.weekWorkingHoursOptions$ = this.cmsCachedService.getUserDataByName("working-hours-per-week");
    this.slotOptions$ = this.cmsCachedService.getUserDataByName("planned-slot");
    this.workAssignmentOptions$ = this.cmsCachedService.getUserDataByName("ideal-working-assignment");
    this.wishIndustry$ = this.cmsCachedService.getUserDataByName("industry");
    this.languageOptions$ = this.cmsCachedService.getUserDataByName('preferred-languages');
    this.companyTypeOptions$ = this.cmsCachedService.getUserDataByName('company-type');
    this.leadershipResponsibility$ = this.cmsCachedService.getUserDataByName('leadership-responsibility');
    this.radiusOptions$ = this.cmsCachedService.getUserDataByName('radius', false);
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      desiredJob: this.fb.group({
        developmentPath: [this.talent.desiredJob?.developmentPath, []],
        wishSector: [this.talent.desiredJob?.wishSector, []],
        wishIndustry: [this.talent.desiredJob?.wishIndustry, []],
        wishDepartment: [this.talent.desiredJob?.wishDepartment, []],
        preferredWorkingLanguage: [this.talent.desiredJob?.preferredWorkingLanguage, []],
        openForTandem: [this.talent.desiredJob?.openForTandem, []],
        openForTandemReason: [{value: this.talent.desiredJob?.openForTandemReason, disabled:this.talent.desiredJob?.openForTandem}, []],
        desiredCompanyType: [
          {value: this.talent.desiredJob?.desiredCompanyType, disabled:this.talent.desiredJob?.desiredCompanyTypeText},
          []],
        desiredCompanyTypeText: [this.talent.desiredJob?.desiredCompanyTypeText, []],
        leadershipResponsibility: [this.talent.desiredJob?.leadershipResponsibility, []],
        idealWorkingLocationDistance: [{value: this.talent.desiredJob?.idealWorkingLocationDistance, disabled:!this.talent.desiredPlaceOfWork?.idealWorkingLocation?.postalCode}, []],
      }),
      desiredSchedule: this.fb.group({
        workingHoursPerWeek: [this.talent.desiredSchedule?.workingHoursPerWeek, []],
        workingHoursPerWeekPlanned: [this.talent.desiredSchedule?.workingHoursPerWeekPlanned, []],
        plannedSlots: [this.talent.desiredSchedule?.plannedSlots, []],
      }),
      desiredPlaceOfWork: this.fb.group({
        idealWorkingAssignments: [this.talent.desiredPlaceOfWork?.idealWorkingAssignments, []],
        idealWorkingLocation: [this.talent.desiredPlaceOfWork?.idealWorkingLocation, [Validators.minLength(4)]],
        travelWillingness: [this.talent.desiredPlaceOfWork?.travelWillingness || 1, []],
      }),
      salaryExpectations: [this.talent.salaryExpectations, [Validators.required]],
    });

    this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) =>
      this.formChange.emit({
        ...this.talent,
        salaryExpectations: value.salaryExpectations,
        desiredJob: {
          ...this.talent.desiredJob,
          ...value.desiredJob,
        },
        desiredSchedule: {
          ...this.talent.desiredSchedule,
          ...value.desiredSchedule,
        },
        desiredPlaceOfWork: {
          ...this.talent.desiredPlaceOfWork,
          ...value.desiredPlaceOfWork,
        },
      }),
    );

    this.formGroup.updateValueAndValidity();
  }

  isStepValid(): boolean {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
    return this.formGroup.valid;
  }

  submitForm() {
    if(!this.isStepValid()) return;
    //this.loading = true;
    this.submitChange.emit();
  }

  onCheckedChange(checked: boolean) {
    const reasonControl = this.formGroup.controls['desiredJob'].get('openForTandemReason');
    checked ? reasonControl?.disable() : reasonControl?.enable();
  }

  onOwnCompanyChange() {
    Promise.resolve().then(() => this.ownCompanyTypeControl?.value ? this.companyTypeControl?.disable(): this.companyTypeControl?.enable() );
  }

  onIdealWorkingLocationChange() {
    Promise.resolve().then(() => {
      if (!this.idealWorkingLocationControl?.value.postalCode || this.idealWorkingLocationControl?.invalid) {
        this.idealWorkingLocationDistanceControl?.setValue(null);
        setTimeout(() => {
          this.idealWorkingLocationDistanceControl?.disable();
        });
      } else {
        this.idealWorkingLocationDistanceControl?.enable();
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  protected readonly compareCmsNameValue = compareCmsNameValue;
}
