<nz-page-header class="large p-r-0">
  <nz-page-header-title>{{'job-offer.page.title' | translate}}</nz-page-header-title>
  <nz-page-header-extra class="job-offer-create">
    <button *ngIf="(isMobileView$ | async) === false" nz-button nzType="primary"
            class="job-offer-create__btn"
            [disabled]="(hasReviewedStatus$ | async) === false || (hasSelfServiceStatus$ | async) === false"
            [routerLink]="['/', 'mission-partner', 'job-offers', 'create']"
    >
      {{'job-offer.create.button' | translate}}
    </button>
    <button *ngIf="(isMobileView$ | async)" nz-button nzType="primary" nzShape="circle"
            class="job-offer-create__btn"
            [disabled]="(hasReviewedStatus$ | async) === false || (hasSelfServiceStatus$ | async) === false"
            [routerLink]="['/', 'mission-partner', 'job-offers', 'create']"
    >
      <span nz-icon nzType="plus"></span>
    </button>
  </nz-page-header-extra>
</nz-page-header>


<ng-container *ngIf="(jobOfferList$ | async) as jobOfferList; else loadingTmpl">
  <mh-mp-job-offer-list [jobOfferListActive]="jobOfferListActive"
                        [jobOfferListArchived]="jobOfferListArchived"
                        [hasReviewedStatus]="$any(hasReviewedStatus$ | async)"
                        (archiveJobOffer)="onArchiveJobOffer($event)"
                        (unArchiveJobOffer)="onUnArchiveJobOffer($event)"></mh-mp-job-offer-list>
</ng-container>
<ng-template #loadingTmpl><nz-spin nzSimple></nz-spin></ng-template>
