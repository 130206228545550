import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  JobOffer,
  MatchedTalent,
  MatchedTalentStatus
} from "../../api/job-offer/job-offer.types";
import { PaginatedContent } from "../../api/common.types";
import { AccountType } from "../../pages/login-page/login-page-data/login.types";

@Component({
  selector: "mh-job-offer-matches-list",
  templateUrl: "./job-offer-matches-list.component.html",
  styleUrls: ["./job-offer-matches-list.component.less"],
})
export class JobOfferMatchesListComponent {
  @Input() matches!: PaginatedContent<MatchedTalent>;
  @Input() jobOffer!: JobOffer;
  @Input() loading = false;
  @Input() hasReviewedStatus = false;
  @Input() hasSelfServiceStatus = false;
  @Input() viewerAccountType?: AccountType;
  @Input() isMobile: boolean | null = false;
  @Output() talentClick = new EventEmitter<MatchedTalent>();
  @Output() pageSizeChange = new EventEmitter<number>();
  @Output() pageIndexChange = new EventEmitter<number>();
  @Output() startConversation = new EventEmitter<MatchedTalent>();
  @Output() toConversation = new EventEmitter<MatchedTalent>();
  @Output() startConversationAsAdmin = new EventEmitter<MatchedTalent>();
  @Output() toConversationAsAdmin = new EventEmitter<MatchedTalent>();
  @Output() doArchive = new EventEmitter<MatchedTalent>();
  @Output() doUnArchive = new EventEmitter<MatchedTalent>();
  @Output() openCommentModal = new EventEmitter<MatchedTalent>();
  @Output() setTalentFavourite = new EventEmitter<MatchedTalent>();

  // constructor() {}

  onTalentClick(event: Event, talent: MatchedTalent) {
    if (event) event.stopPropagation();
    this.talentClick.emit(talent);
  }

  onPageSizeChange(pageSize: number) {
    this.pageSizeChange.emit(pageSize);
  }

  onPageIndexChange(pageIndex: number) {
    this.pageIndexChange.emit(pageIndex);
  }

  onStartConversation(talent: MatchedTalent, event?: Event): void {
    if (event) event.stopPropagation();
    this.startConversation.emit(talent);
  }

  onToConversation(talent: MatchedTalent, event?: Event) {
    if (event) event.stopPropagation();
    this.toConversation.emit(talent);
  }

  onStartConversationAsAdmin(talent: MatchedTalent, event?: Event): void {
    if (event) event.stopPropagation();
    this.startConversationAsAdmin.emit(talent);
  }

  onToConversationAsAdmin(talent: MatchedTalent, event?: Event) {
    if (event) event.stopPropagation();
    this.toConversationAsAdmin.emit(talent);
  }

  onArchive(talent: MatchedTalent, event?: Event) {
    if (event) event.stopPropagation();
    this.doArchive.emit(talent);
  }

  onUnArchive(talent: MatchedTalent, event?: Event) {
    if (event) event.stopPropagation();
    this.doUnArchive.emit(talent);
  }

  onOpenCommentModal(talent: MatchedTalent, event?: Event) {
    if (event) event.stopPropagation();
    this.openCommentModal.emit(talent);
  }

  onSetTalentFavourite(talent: MatchedTalent, event?: Event) {
    if (event) event.stopPropagation();
    this.setTalentFavourite.emit(talent);
  }

  protected readonly MatchedTalentStatus = MatchedTalentStatus;
  protected readonly AccountType = AccountType;
}
