<nz-list [nzItemLayout]="'horizontal'">
  <nz-comment
    *ngFor="let comment of comments"
    [nzAuthor]="comment.authorName"
    [nzDatetime]="comment.creationTimestamp"
  >
    <nz-comment-content>
      <p>{{ comment.text }}</p>
    </nz-comment-content>
  </nz-comment>
</nz-list>

<nz-comment>
  <nz-comment-content>
    <form nz-form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
      <nz-form-item>
        <nz-form-control [nzSpan]="24" [nzErrorTip]="textErrorTpl">
          <textarea nz-input rows="4" formControlName="text"></textarea>
          <ng-template #textErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">{{'admin.comment.error.required' | translate}}</ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item class="submit-btn">
        <button nz-button nzType="primary">
          {{ 'admin.comment.button.add' | translate }}
        </button>
      </nz-form-item>
    </form>
  </nz-comment-content>
</nz-comment>
