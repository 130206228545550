import { Component, OnInit, ViewContainerRef } from "@angular/core";
import { PaginatedContent } from "../../../../api/common.types";
import {
  AccountFilter,
  AccountId,
  Filter,
  getStatusShortName,
  GroupFilter,
  MissionPartnerAccount,
  TalentAccount
} from "../../home-admin-data/account.types";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { AccountService } from "../../home-admin-data/account.service";
import { NzTableQueryParams } from "ng-zorro-antd/table";
import { take } from "rxjs";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { NzModalService } from "ng-zorro-antd/modal";
import { TranslateService } from "@ngx-translate/core";
import { AccountPasswordDialogComponent } from "../../../../../../../common-ui/src/lib/account-password-dialog/account-password-dialog.component";
import { AccountStatus, AccountType } from "../../../login-page/login-page-data/login.types";
import { DatePipe } from "@angular/common";
import { AccountCommentsDialogComponent } from "../../../../../../../common-ui/src/lib/account-comments-dialog/account-comments-dialog.component";
import { Clipboard } from "@angular/cdk/clipboard";
import { FilterStorageService } from "../../../../api/filter-storage/filter-storage.service";
import { FiltersPage } from "../../../../api/filter-storage/filter-storage.types";

const FILTERS_PAGE = FiltersPage.MISSION_PARTNER_LIST;

@Component({
  selector: "mh-admin-mission-partner-list",
  templateUrl: "./admin-mission-partner-list.component.html",
  styleUrls: ["./admin-mission-partner-list.component.less"],
})
export class AdminMissionPartnerListComponent implements OnInit {
  accountList?: PaginatedContent<MissionPartnerAccount>;
  loading = true;
  isApiLoading = false;
  searchForm!: UntypedFormGroup;
  preventDoubleFetch = true;

  constructor(
    private fb: UntypedFormBuilder,
    private accountService: AccountService,
    private filterStorageService: FilterStorageService,
    private notificationService: NzNotificationService,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private readonly translateService: TranslateService,
    private datePipe: DatePipe,
    private clipboard: Clipboard,
  ) {
  }

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      searchTerm: [null],
      statusReviewed: [null],
      timeRange: [[]],
    });

    const storedFilters = this.filterStorageService.getStoredFilters()?.[FILTERS_PAGE];
    if (storedFilters) {
      this.searchForm.patchValue(storedFilters);
    }

    this.fetchAccounts({...this.getDefaultQueryParams(), ...this.getFilterFormValues()});
  }

  fetchAccounts(filter: AccountFilter) {
    this.loading = true;
    this.accountService.loadMissionPartnerList(filter)
      .pipe(take(1))
      .subscribe((paginatedContent) => {
        this.accountList = paginatedContent;
        this.getSearchField("timeRange")?.patchValue(paginatedContent.timeRange);
        this.loading = false;
      });
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    if (this.loading) return;
    if (this.preventDoubleFetch) {
      this.preventDoubleFetch = false;
      return;
    }
    const {pageSize, pageIndex, sort, filter} = params;

    const currentSort = sort.find((item) => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;

    let change: AccountFilter = {
      paging: {
        page: pageIndex,
        itemsOnPage: pageSize,
      },
      ...this.getFilterFormValues(),
    }

    if (sortField && sortOrder) {
      change = {
        ...change,
        sort: [
          {
            order: sortOrder == 'ascend' ? 'ASC' : 'DESC',
            field: sortField
          }
        ]
      }
    }

    this.onQueryChange(change);
    this.loading = true;
  }

  submitFilterForm(): void {
    this.filterStorageService.saveFilters(this.searchForm, FILTERS_PAGE);
    this.onQueryChange({
      paging: {
        page: 1,
        itemsOnPage: this.accountList?.paging?.itemsOnPage || 0,
      },
      ...this.getFilterFormValues(),
    });
  }

  getFilterFormValues() {
    const searchField = this.getSearchField("searchTerm");
    const timeRangeField = this.getSearchField("timeRange");
    const statusReviewed = this.getSearchField("statusReviewed");

    const filterFormValues = {
      searchTerm: searchField?.value || "",
      timeRange: timeRangeField?.value || [],
      groupFilters: [] as GroupFilter[],
      filters: [] as Filter[],
    };

    if (statusReviewed?.value === "REVIEWED") {
      filterFormValues.filters?.push({
        value: AccountStatus.REVIEWED,
        field: "ACCOUNT_STATUS",
      });
    }

    if (statusReviewed?.value === "NOT_REVIEWED") {
      filterFormValues.filters?.push({
        value: AccountStatus.REVIEWED,
        field: "EXCLUDED_ACCOUNT_STATUS",
      });
    }

    return filterFormValues;
  }

  getSearchField(name: string) {
    return this.searchForm.get(name);
  }

  onSignInAs(accountId: string) {
    this.accountService.signInAs(accountId);
  }

  sendPasswordResetEmail(accountId: AccountId, email: string) {
    this.accountService.sendPasswordResetEmail(accountId, email).subscribe((success) => {
      if (success) {
        this.notificationService.success('', this.translateService.instant('admin.password-reset.success'));
      } else {
        this.notificationService.error('', this.translateService.instant('notification.error'));
      }
    });
  }

  openSetPasswordModal(account: TalentAccount | MissionPartnerAccount) {
    const modal = this.modal.create({
      nzContent: AccountPasswordDialogComponent,
      nzMaskClosable: false,
      nzViewContainerRef: this.viewContainerRef,
      nzData: {account},
    });
  }

  openCommentModal(account: TalentAccount | MissionPartnerAccount) {
    const modal = this.modal.create({
      nzContent: AccountCommentsDialogComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzCentered: true,
      nzWidth: "800px",
      nzStyle: { padding: "30px 0" },
      nzBodyStyle: { padding: "24px 24px 0" },
      nzMaskClosable: false,
      nzData: { account },
      nzFooter: null,
    });
  }

  deleteAccount(accountId: AccountId, accountType = AccountType.MISSION_PARTNER) {
    this.accountService.deleteAccount(accountId, accountType).subscribe((success) => {
      if (success) {
        if (this.accountList?.items) {
          this.accountList.items = this.accountList.items.filter((account) => account.accountId.internalId !== accountId.internalId)
        }
        this.notificationService.success('', this.translateService.instant('admin.account-delete.success'));
      } else {
        this.notificationService.error('', this.translateService.instant('notification.error'));
      }
    });
  }

  showDeleteConfirm(account: TalentAccount | MissionPartnerAccount) {
    this.modal.confirm({
      nzTitle: this.translateService.instant('admin.account-delete.confirm.title'),
      nzContent: `<b>${this.translateService.instant('admin.account-delete.confirm.text')}</b>`,
      nzOkText: this.translateService.instant('admin.account-delete.confirm.ok.btn'),
      nzCancelText: this.translateService.instant('admin.account-delete.confirm.cancel.btn'),
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => this.deleteAccount(account.accountId),
    });
  }

  downloadData(accountId: AccountId, accountType = AccountType.MISSION_PARTNER) {
    const currentDate = new Date();
    const formattedDate = this.datePipe.transform(currentDate, 'yyyy-MM-dd');
    const fileName = `${formattedDate}-${accountId.internalId}_export.json`;

    this.accountService.downloadData(accountId, accountType, fileName).subscribe();
  }

  downloadMissionPartnerListCSV() {
    const currentDate = new Date();
    const formattedDate = this.datePipe.transform(currentDate, "yyyy-MM-dd");
    const fileName = `${formattedDate}_mission_partner_list_export.csv`;

    this.isApiLoading = true;
    this.accountService.downloadMissionPartnerListCSV(fileName).subscribe(() => this.isApiLoading = false);
  }

  reviewMissionPartner(accountId: string) {
    this.accountService.reviewMissionPartner(accountId).subscribe((success) => {
      if (success) {
        if (this.accountList?.items){
          this.accountList.items = this.accountList.items.map((item) => {
            if (item.accountId.internalId === accountId) {
              item.statuses.push(AccountStatus.REVIEWED);
            }
            return item;
          });
        }
        this.notificationService.success('', this.translateService.instant('admin.review-mission-partner.success'));
      } else {
        this.notificationService.error('', this.translateService.instant('notification.error'));
      }
    });
  }

  discardReviewMissionPartner(accountId: string) {
    this.accountService.discardReviewMissionPartner(accountId).subscribe((success) => {
      if (success) {
        if (this.accountList?.items) {
          this.accountList.items = this.accountList.items.map((item) => {
            if (item.accountId.internalId === accountId) {
              item.statuses = item.statuses.filter(status => status !== AccountStatus.REVIEWED);
            }
            return item;
          });
        }
        this.notificationService.success('', this.translateService.instant('admin.discard-review-mission-partner.success'));
      } else {
        this.notificationService.error('', this.translateService.instant('notification.error'));
      }
    });
  }

  setSelfServiceStatusMissionPartner(accountId: string) {
    this.accountService.setSelfServiceStatusMissionPartner(accountId).subscribe((success) => {
      if (success) {
        if (this.accountList?.items) {
          this.accountList.items = this.accountList.items.map((item) => {
            if (item.accountId.internalId === accountId) {
              item.statuses.push(AccountStatus.SELF_SERVICE);
            }
            return item;
          });
        }
        this.notificationService.success('', this.translateService.instant('admin.set-self-service-status-mission-partner.success'));
      } else {
        this.notificationService.error('', this.translateService.instant('notification.error'));
      }
    });
  }

  removeSelfServiceStatusMissionPartner(accountId: string) {
    this.accountService.removeSelfServiceStatusMissionPartner(accountId).subscribe((success) => {
      if (success) {
        if (this.accountList?.items) {
          this.accountList.items = this.accountList.items.map((item) => {
            if (item.accountId.internalId === accountId) {
              item.statuses = item.statuses.filter(status => status !== AccountStatus.SELF_SERVICE);
            }
            return item;
          });
        }
        this.notificationService.success('', this.translateService.instant('admin.remove-self-service-status-mission-partner.success'));
      } else {
        this.notificationService.error('', this.translateService.instant('notification.error'));
      }
    });
  }

  setConfirmedStatus(missionPartner: MissionPartnerAccount) {
    this.accountService.addAccountConfirmedStatus(missionPartner.accountId.internalId)
      .subscribe((success) => {
        if (success) {
          if(!missionPartner.statuses.includes(AccountStatus.CONFIRMED)) missionPartner.statuses.push(AccountStatus.CONFIRMED);
          if(!missionPartner.statuses.includes(AccountStatus.REGISTERED)) missionPartner.statuses.push(AccountStatus.REGISTERED);
          this.notificationService.success('', this.translateService.instant('admin.add-confirmed-status.success'));
        } else {
          this.notificationService.error('', this.translateService.instant('notification.error'));
        }
      });
  }

  copyId(id: string) {
    const isCopySuccessful = this.clipboard.copy(id);
    if (isCopySuccessful) {
      this.notificationService.success("", this.translateService.instant("admin.copy-id.success"));
    }
  }

  onQueryChange(filter: AccountFilter) {
    this.fetchAccounts(filter);
  }

  getDefaultQueryParams() {
    const from = new Date(2023, 0, 1);
    const to = new Date(new Date().getFullYear(), 11, 31);

    return {
      paging: {
        page: 1,
        itemsOnPage: 100,
      },
      searchTerm: '',
      timeRange: [from, to],
    };
  }

  protected readonly getStatusShortName = getStatusShortName;
  protected readonly AccountStatus = AccountStatus;
}
