import { Inject, Injectable, Injector } from "@angular/core";
import { BaseApi } from "../base-api";
import { HttpClient } from "@angular/common/http";
import { APP_CONFIGURATION } from "../../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../../core/src/lib/app-config/config.types";
import { catchError, map, Observable } from "rxjs";
import { ApiPaginatedResponseDTO, ApiResponseDTO, initialPaginatedContent, PaginatedContent } from "../common.types";
import { JobOfferDTO, JobOfferTalentCommentDTO, MatchedTalentDTO } from "./job-offer.types";
import { AccountFilterDTO } from "../../pages/home-admin/home-admin-data/account.types";

@Injectable({ providedIn: "root" })
export class JobOfferApi extends BaseApi {
  private basePath: string;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly injector: Injector,
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
  ) {
    super(injector);
    this.basePath = appConfig.apiEndpointUrl;
  }

  loadMyJobOfferList(): Observable<Array<JobOfferDTO>> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/job-opportunity/my/list`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data;
        } else {
          super.handleErrorResponse(response);
        }
      }),
    );
  }

  loadJobOffer(id: string): Observable<JobOfferDTO> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/job-opportunity/${id}`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data;
        } else {
          super.handleErrorResponse(response);
        }
      }),
    );
  }

  createJopOffer(jo: JobOfferDTO) {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/job-opportunity/create`, jo).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  updateJobOffer(jo: JobOfferDTO): Observable<boolean> {
    return this.httpClient.put<ApiResponseDTO>(`${this.basePath}/job-opportunity/update`, jo).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  deleteJobOffer(id: string): Observable<boolean> {
    return this.httpClient.delete<ApiResponseDTO>(`${this.basePath}/job-opportunity/${id}`, {}).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  archiveJobOffer(id: string): Observable<boolean> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/job-opportunity/${id}/archive`, {}).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  unArchiveJobOffer(jobOffer: JobOfferDTO): Observable<boolean> {
    const request = {
      jobOpportunityId: jobOffer.id,
      archived: false};
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/job-opportunity/job-opportunity/change-state`, request).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  loadJobOfferMatchedTalents(id: string): Observable<Array<MatchedTalentDTO>> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/job-opportunity/${id}/talents/list`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data;
        } else {
          super.handleErrorResponse(response);
        }
      }),
    );
  }

  loadJobOfferMatchedTalentList(filter: AccountFilterDTO): Observable<PaginatedContent<MatchedTalentDTO>> {
    return this.httpClient
      .post<ApiPaginatedResponseDTO<MatchedTalentDTO>>(`${this.basePath}/job-opportunity/talents/list`, { ...filter })
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.data;
          } else {
            super.handleErrorResponse(response);
            return initialPaginatedContent;
          }
        }),
        catchError(super.handleHttpError),
      );
  }

  readJobOfferMatch(jobOfferId: string, talentId: string): Observable<boolean> {
    const request = {
      accountId: {internalId: talentId},
      jobOpportunityId: jobOfferId
    };
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/job-opportunity/match/read`, request).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  archiveJobOfferMatch(jobOfferId: string, talentId: string): Observable<boolean> {
    const request = {
      accountId: {internalId: talentId},
      jobOpportunityId: jobOfferId
    };
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/job-opportunity/match/archive`, request).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  unArchiveJobOfferMatch(jobOfferId: string, talentId: string): Observable<boolean> {
    const request = {
      accountId: {internalId: talentId},
      jobOpportunityId: jobOfferId
    };
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/job-opportunity/match/unarchive`, request).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }


  loadJobOfferTalentComments(jobOfferId: string, talentId: string): Observable<Array<JobOfferTalentCommentDTO>> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/job-opportunity/${jobOfferId}/talents/${talentId}/comments`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data;
        } else {
          super.handleErrorResponse(response);
          return response;
        }
      }),
    );
  }

  sendJobOfferTalentComment(jobOfferId: string, talentId: string, text: string): Observable<JobOfferTalentCommentDTO> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/job-opportunity/${jobOfferId}/talents/${talentId}/comments`, { text })
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.data;
          } else {
            super.handleErrorResponse(response);
            return response;
          }
        }),
      );
  }

  deleteJobOfferTalentComments(jobOfferId: string, commentId: string): Observable<boolean> {
    return this.httpClient.delete<ApiResponseDTO>(`${this.basePath}/job-opportunity/${jobOfferId}/comments/${commentId}`).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  setTalentFavourite(jobOfferId: string, talentId: string, setFavourite: boolean): Observable<boolean> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/job-opportunity/${jobOfferId}/talents/${talentId}/favourite/${setFavourite}`, {}).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }
}
