import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { MissionPartner } from "../../../../../../api/sign-up/signup.types";
import { FormBuilder, FormGroup } from "@angular/forms";
import {Observable, Subject, takeUntil} from "rxjs";
import {CmsNameValue, compareCmsNameValue} from "../../../../../../api/cms/cms.types";
import {CmsCachedService} from "../../../../../../api/cms/cms-cached.service";

@Component({
  selector: "mh-profile-diversities",
  templateUrl: "./profile-diversities.component.html",
  styleUrls: ["./profile-diversities.component.less"],
})
export class ProfileDiversitiesComponent implements OnInit, OnDestroy {
  @Input() missionPartner!: MissionPartner;
  @Output() formChange = new EventEmitter<MissionPartner>();
  @Output() submitChange = new EventEmitter<void>();

  public formGroup!: FormGroup;
  private readonly destroy$ = new Subject<void>();

  public workAssignmentOptions$: Observable<Array<CmsNameValue>>;

  constructor(private fb: FormBuilder, private cmsCachedService: CmsCachedService) {
    this.workAssignmentOptions$ = this.cmsCachedService.getUserDataByName("ideal-working-assignment");
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      diversities: this.fb.group({
        diversity1: [this.missionPartner.diversities.diversity1, []],
        diversity2: [this.missionPartner.diversities.diversity2, []],
        diversity3: [this.missionPartner.diversities.diversity3, []],
        diversity4: [this.missionPartner.diversities.diversity4, []],
        diversity5: [this.missionPartner.diversities.diversity5, []],
        diversity6: [this.missionPartner.diversities.diversity6, []],
        diversity7: [this.missionPartner.diversities.diversity7, []],
        diversity8: [this.missionPartner.diversities.diversity8, []],
        diversity9: [this.missionPartner.diversities.diversity9, []],
        diversity10: [this.missionPartner.diversities.diversity10, []],
      }),
      complianceKpi: this.fb.group({
        kpiPossibleWorkingAssignments: [this.missionPartner.complianceKpi.kpiPossibleWorkingAssignments, []],
        kpiPercentagePartTimeLeadership: [this.missionPartner.complianceKpi.kpiPercentagePartTimeLeadership, []],
        kpiPercentageFemaleLeadership: [this.missionPartner.complianceKpi.kpiPercentageFemaleLeadership, []],
      }),
    });
    this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) =>
      this.formChange.emit({
        ...this.missionPartner,
        diversities: {
          ...this.missionPartner.diversities,
          ...value.diversities,
        },
        complianceKpi: {
          ...this.missionPartner.complianceKpi,
          ...value.complianceKpi,
        }
      }),
    );
  }

  isStepValid(): boolean {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
    return this.formGroup.valid;
  }

  submitForm() {
    if(!this.isStepValid()) return;
    this.submitChange.emit();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  protected readonly compareCmsNameValue = compareCmsNameValue;
}
