import { ChangeDetectorRef, Component, OnInit, ViewContainerRef } from "@angular/core";
import { PaginatedContent } from "../../../../api/common.types";
import {
  AccountFilter,
  GroupFilter,
} from "../../../home-admin/home-admin-data/account.types";
import { map, Observable, take, tap } from "rxjs";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { NzTableQueryParams } from "ng-zorro-antd/table";
import { MissionPartner } from "../../../../api/sign-up/signup.types";
import { ProfileService } from "../../../../api/profile/profile.service";
import { BreakpointObserver } from "@angular/cdk/layout";
import { InitiateConversationRequest } from "../../../../api/messages/messages.types";
import { Store } from "@ngrx/store";
import {
  jobOfferFeature,
  JobOfferState
} from "../../../home-mission-partner/subpages/mission-partner-job-offers/job-offer-data/store/job-offer.reducer";
import { messagesFeature, MessagesState } from "../../../../common-componnets/messages/store/messages.reducer";
import { MessagesService } from "../../../../api/messages/messages.service";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { TranslateService } from "@ngx-translate/core";
import { NzModalService } from "ng-zorro-antd/modal";
import {
  CreateConversationDialogComponent
} from "../../../../common-componnets/create-conversation-dialog/create-conversation-dialog.component";

@Component({
  selector: "mh-talent-mission-partners",
  templateUrl: "./talent-mission-partners.component.html",
  styleUrls: ["./talent-mission-partners.component.less"],
})
export class TalentMissionPartnersComponent implements OnInit {
  missionPartnerList?: PaginatedContent<MissionPartner>;
  loading = true;
  searchForm!: UntypedFormGroup;
  preventDoubleFetch = true;

  isMobileView$: Observable<boolean>;

  constructor(
    private fb: UntypedFormBuilder,
    private profileService: ProfileService,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly store: Store<{ [jobOfferFeature]: JobOfferState, [messagesFeature]: MessagesState }>,
    private messagesService: MessagesService,
    private notificationService: NzNotificationService,
    private translateService: TranslateService,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
  ) {
    this.isMobileView$ = this.breakpointObserver.observe(["(max-width: 992px)"]).pipe(
      map((breakpoint) => breakpoint.matches),
      tap((mobileView) => {
        this.changeDetectorRef.detectChanges();
      }),
    );
  }

  ngOnInit(): void {
    this.fetchAccounts(this.getDefaultQueryParams());
    this.searchForm = this.fb.group({
      searchTerm: [null],
    });
  }

  fetchAccounts(filter: AccountFilter) {
    this.loading = true;
    this.profileService.loadMissionPartnerList(filter)
      .pipe(take(1))
      .subscribe((paginatedContent) => {
        this.missionPartnerList = paginatedContent;
        this.loading = false;
      });
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    if (this.loading) return;
    if (this.preventDoubleFetch) {
      this.preventDoubleFetch = false;
      return;
    }
    const {pageSize, pageIndex, sort, filter} = params;

    const currentSort = sort.find((item) => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;

    let change: AccountFilter = {
      paging: {
        page: pageIndex,
        itemsOnPage: pageSize,
      },
      sort: [
        {
          order: 'ASC',
          field: 'COMPANY_NAME',
        }
      ],
      ...this.getFilterFormValues(),
    }

    if (sortField && sortOrder) {
      change = {
        ...change,
        sort: [
          {
            order: sortOrder == 'ascend' ? 'ASC' : 'DESC',
            field: sortField
          }
        ]
      }
    }

    this.onQueryChange(change);
    this.loading = true;
  }

  submitFilterForm(): void {
    this.onQueryChange({
      paging: {
        page: 1,
        itemsOnPage: this.missionPartnerList?.paging?.itemsOnPage || 0,
      },
      ...this.getFilterFormValues(),
    });
  }

  getFilterFormValues() {
    const searchField = this.getSearchField("searchTerm");

    const filterFormValues = {
      searchTerm: searchField?.value || "",
      groupFilters: [] as GroupFilter[],
    };

    return filterFormValues;
  }

  getSearchField(name: string) {
    return this.searchForm.get(name);
  }

  onQueryChange(filter: AccountFilter) {
    this.fetchAccounts(filter);
  }

  getDefaultQueryParams(): AccountFilter {
    return {
      paging: {
        page: 1,
        itemsOnPage: 10,
      },
      searchTerm: '',
      sort: [
        {
          order: 'ASC',
          field: 'COMPANY_NAME',
        }
      ]
    };
  }

  openCreateConversationModal(event: Event, missionPartner: MissionPartner) {
    event.stopPropagation();
    const modal = this.modal.create({
      nzContent: CreateConversationDialogComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzData: { suggestRequest: { missionPartnerId: missionPartner.accountId?.internalId } },
      nzWidth: 800,
      nzOnOk: (modalCmp) => this.doCreateConversation(missionPartner, modalCmp.formGroup.value)
    });
  }

  doCreateConversation(missionPartner: MissionPartner, formData: Partial<InitiateConversationRequest>) {
    if (!missionPartner.accountId?.internalId) {
      return;
    }
    this.messagesService.initiateConversation(missionPartner.accountId.internalId, formData.messageText as string).subscribe((conversation) => {
      if (conversation) {
        this.notificationService.success("", this.translateService.instant("notification.success"));
        missionPartner.conversationId = conversation.id;
      } else {
        this.notificationService.error("", this.translateService.instant("notification.error"));
      }
    });
  }

  calculateNumber(index: number): number {
    const currentPage = this.missionPartnerList?.paging?.page || 1;
    const itemsOnPage = this.missionPartnerList?.paging?.itemsOnPage || 1;

    return (index + 1) + (currentPage * itemsOnPage) - itemsOnPage;
  }

  stopPropagation(event: MouseEvent) {
    event.stopPropagation();
  }
}
