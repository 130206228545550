import { Injectable } from "@angular/core";
import {
  Education,
  MissionPartner,
  MissionPartnerComplianceKpi, MissionPartnerData, MissionPartnerDiversities,
  Talent,
  TalentData,
  TrackRecord
} from "../sign-up/signup.types";
import {
  EducationDTO, MissionPartnerComplianceKpiDTO, MissionPartnerDataDTO, MissionPartnerDiversitiesDTO,
  MissionPartnerDTO,
  MissionPartnerWithDataSpaceDTO,
  TalentDataDTO,
  TalentDTO,
  TalentWithDataSpaceDTO,
  TrackRecordDTO,
} from "../sign-up/signup-api.types";
import { DocumentSerializer } from "../document/document.serializer";
import { CmsNameValue } from "../cms/cms.types";
import { ReferencesSerializer } from "../references/references.serializer";
import { DataSpaceSerializer } from "../dataspace/dataspace.serializer";
import { CommonService } from "../common.service";
import { CmsSerializer } from "../cms/cms.serializer";

@Injectable({
  providedIn: "root",
})
export class ProfileSerializer {
  constructor(
    private documentSerializer: DocumentSerializer,
    private referencesSerializer: ReferencesSerializer,
    private dataSpaceSerializer: DataSpaceSerializer,
    private commonService: CommonService,
    private cmsSerializer: CmsSerializer,
  ) {}

  deserializeTalent(dto: TalentDTO): Talent {
    return {
      ...dto,
      education: this.deserializeEducations(dto.education),
      birthDate: dto.birthDate ? new Date(dto.birthDate) : undefined,
      cv: dto.cv ? this.documentSerializer.deserializeDocument(dto.cv) : undefined,
      salaryExpectations: {
        min: this.salaryToCmsValue(dto.salaryExpectationMin),
        max: this.salaryToCmsValue(dto.salaryExpectationMax),
      },
      trackRecord: dto.trackRecord ? this.deserializeTrackRecords(dto.trackRecord) : [],
      oldTrackRecord: dto.oldTrackRecord,
      leadershipTeamSize: !dto.leadershipTeamSize ? undefined : dto.leadershipTeamSize,
    };
  }

  deserializeTalentWithDataSpaces(dto: TalentWithDataSpaceDTO): Talent {
    return {
      ...this.deserializeTalent(dto.user),
      dataspace: this.dataSpaceSerializer.deserializeDataSpaces(dto.dataspaces),
    };
  }

  deserializeMissionPartnerWithDataSpaces(dto: MissionPartnerWithDataSpaceDTO): MissionPartner {
    return {
      ...this.deserializeMissionPartner(dto.user),
      dataspace: this.dataSpaceSerializer.deserializeDataSpaces(dto.dataspaces),
    };
  }

  deserializeTalentData(dto: TalentDataDTO): TalentData {
    return {
      ...dto,
      data: this.deserializeTalent(dto.data),
      files: this.documentSerializer.deserializeDocuments(dto.files.items),
      references: this.referencesSerializer.deserializeReferences(dto.references),
    };
  }

  deserializeMissionPartnerData(dto: MissionPartnerDataDTO): MissionPartnerData {
    return {
      ...dto,
      data: this.deserializeMissionPartner(dto.data),
      references: this.referencesSerializer.deserializeReferences(dto.references),
    };
  }

  deserializeMissionPartners(dtos: Array<MissionPartnerDTO>): Array<MissionPartner> {
    return dtos.map((dto) => this.deserializeMissionPartner(dto));
  }

  deserializeMissionPartner(dto: MissionPartnerDTO): MissionPartner {
    return {
      ...dto,
      street: dto.address?.street,
      houseNumber: dto.address?.houseNumber,
      avatarUrl: this.commonService.toAbsUrl(dto.avatar?.avatarUrl),
      complianceKpi: this.deserializeComplianceKpi(dto.complianceKpi),
      diversities: this.deserializeDiversities(dto.diversities),
      hashtags: dto.hashtags?.length ? dto.hashtags.filter((tag) => !!tag) : [],
    };
  }

  serializeTalent(talent: Talent): TalentDTO {
    return {
      ...talent,
      education: this.serialiseEducations(talent.education),
      birthDate: talent.birthDate?.getTime(),
      cv: undefined,
      salaryExpectationMin: talent.salaryExpectations?.min?.value,
      salaryExpectationMax: talent.salaryExpectations?.max?.value,
      trackRecord: this.serialiseTrackRecords(talent.trackRecord),
      oldTrackRecord: talent.oldTrackRecord,
    };
  }

  serializeMissionPartner(missionPartner: MissionPartner): MissionPartnerDTO {
    return {
      ...missionPartner,
      address: { ...missionPartner.address, street: missionPartner.street, houseNumber: missionPartner.houseNumber },
      complianceKpi: this.serializeComplianceKpi(missionPartner.complianceKpi),
    };
  }

  serialiseEducations(educations: Array<Education>): Array<EducationDTO> {
    return educations.map((edu) => this.serialiseEducation(edu));
  }

  serialiseEducation(edu: Education): EducationDTO {
    return {
      ...edu,
      graduationYear: edu?.graduationYear ? edu.graduationYear.getFullYear() : undefined,
    };
  }

  deserializeEducations(dtos: Array<EducationDTO>): Array<Education> {
    return dtos.map((education) => this.deserializeEducation(education));
  }
  deserializeEducation(dto: EducationDTO): Education {
    return {
      ...dto,
      graduationYear: dto?.graduationYear ? new Date(dto.graduationYear.toString()) : undefined,
    };
  }

  serialiseTrackRecords(objs: Array<TrackRecord>): Array<TrackRecordDTO> {
    return objs.map((obj) => this.serialiseTrackRecord(obj));
  }

  deserializeTrackRecords(dtos: Array<TrackRecordDTO>): Array<TrackRecord> {
    return dtos.map((dto) => this.deserializeTrackRecord(dto));
  }

  serialiseTrackRecord(obj: TrackRecord): TrackRecordDTO {
    const result: any = { ...obj };

    if (obj.workingUntilNow && obj.employmentDurationFromToNow) {
      result.employmentDurationFrom = this.dateToTimestamp(obj.employmentDurationFromToNow) || undefined;
      result.employmentDurationTo = 0;
    } else {
      result.employmentDurationFrom =
        obj.employmentDurationRange && obj.employmentDurationRange[0]
          ? this.dateToTimestamp(obj.employmentDurationRange[0])
          : undefined;
      result.employmentDurationTo =
        obj.employmentDurationRange && obj.employmentDurationRange[1]
          ? this.dateToTimestamp(obj.employmentDurationRange[1])
          : undefined;
    }

    delete result.employmentDurationFromToNow;
    delete result.employmentDurationRange;
    return result;
  }

  deserializeTrackRecord(dto: TrackRecordDTO): TrackRecord {
    return {
      ...dto,
      employmentDurationFromToNow: dto?.employmentDurationFromToNow && new Date(dto.employmentDurationFromToNow).getTime() ? new Date(dto.employmentDurationFromToNow) : null,
      employmentDurationRange: [
        dto?.employmentDurationFrom && new Date(dto.employmentDurationFrom).getTime() ? new Date(dto.employmentDurationFrom) : undefined,
        dto?.employmentDurationTo && new Date(dto.employmentDurationTo).getTime() ? new Date(dto.employmentDurationTo) : new Date(),
      ],
    };
  }

  deserializeComplianceKpi(dto: MissionPartnerComplianceKpiDTO): MissionPartnerComplianceKpi {
    return {
      kpiPossibleWorkingAssignments: dto.kpiPossibleWorkingAssignments ? this.cmsSerializer.deserializeNameValues(dto.kpiPossibleWorkingAssignments) : [],
      kpiPercentagePartTimeLeadership: dto.kpiPercentagePartTimeLeadership || 0,
      kpiPercentageFemaleLeadership: dto.kpiPercentageFemaleLeadership || 0,
      kpiQuestion1: dto.kpiQuestion1 || '',
      kpiQuestion2: dto.kpiQuestion2 || '',
      kpiQuestion3: dto.kpiQuestion3 || '',
      kpiQuestion4: dto.kpiQuestion4 || '',
      kpiQuestion5: dto.kpiQuestion5 || '',
      kpiQuestion6: dto.kpiQuestion6 || '',
      kpiQuestion7: dto.kpiQuestion7 || '',
      kpiQuestion8: dto.kpiQuestion8 || '',
      kpiQuestion9: dto.kpiQuestion9 || '',
      kpiQuestion10: dto.kpiQuestion10 || '',
    }
  }

  deserializeDiversities(dto: MissionPartnerDiversitiesDTO): MissionPartnerDiversities {
    return {
      diversity1: dto.diversity1 || '',
      diversity2: dto.diversity2 || '',
      diversity3: dto.diversity3 || '',
      diversity4: dto.diversity4 || '',
      diversity5: dto.diversity5 || '',
      diversity6: dto.diversity6 || '',
      diversity7: dto.diversity7 || '',
      diversity8: dto.diversity8 || '',
      diversity9: dto.diversity9 || '',
      diversity10: dto.diversity10 || '',
    }
  }

  serializeComplianceKpi(entry: MissionPartnerComplianceKpi): MissionPartnerComplianceKpiDTO {
    return {
      ...entry,
      kpiPossibleWorkingAssignments: entry?.kpiPossibleWorkingAssignments ? this.cmsSerializer.serializeNameValues(entry.kpiPossibleWorkingAssignments) : [],
    };
  }

  private salaryToCmsValue(salary?: number): CmsNameValue | undefined {
    if (!salary) return undefined;
    return { value: salary, name: salary + "K" };
  }
  private dateToTimestamp(date: Date | undefined): number | undefined {
    return date ? date.getTime() : undefined;
  }
}
