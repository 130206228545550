import { Component, Input, OnInit } from "@angular/core";
import { AuthorizationService } from "../../../../platform-pages/src/lib/api/authorization/authorization.service";
import { ActivatedRoute } from "@angular/router";
import { AccountService } from "../../../../platform-pages/src/lib/pages/home-admin/home-admin-data/account.service";
import { Comment } from "../../../../platform-pages/src/lib/pages/home-admin/home-admin-data/account.types";
import { take } from "rxjs";
import { FormControl, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "mh-account-comments",
  templateUrl: "./account-comments.component.html",
  styleUrls: ["./account-comments.component.less"],
})
export class AccountCommentsComponent implements OnInit {
  @Input() talentId!: string;
  formGroup: FormGroup;
  authorId: string | undefined;
  comments: Comment[] = [];

  constructor(
    private authorizationService: AuthorizationService,
    private route: ActivatedRoute,
    private accountService: AccountService,
  ) {
    this.authorizationService.getAccountId().subscribe((accountId) => {
      this.authorId = accountId;
    });
    this.formGroup = new FormGroup({
      text: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit() {
    this.accountService.loadAccountComments(this.talentId)
      .pipe(take(1))
      .subscribe((comments) => {
        this.comments = comments;
      });
  }

  handleSubmit(): void {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return;
    }
    if (this.talentId && this.authorId && this.formGroup.valid) {
      const text = this.formGroup.get('text')?.value;
      this.accountService.sendAccountComment(this.talentId, text, this.authorId).subscribe((comment) => {
        if (comment) {
          this.formGroup.reset();
          this.comments.push(comment);
        }
      });
    }
  }
}
