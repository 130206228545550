import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SignupPhoneVerifyComponent } from "./signup-phone-verify.component";
import { NzFormModule } from "ng-zorro-antd/form";
import { ReactiveFormsModule } from "@angular/forms";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzInputNumberModule } from "ng-zorro-antd/input-number";
import { CodeInputModule, NzIntlTelInputModule } from "@momhunting/common-ui";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [SignupPhoneVerifyComponent],
  imports: [
    CommonModule,
    NzFormModule,
    ReactiveFormsModule,
    NzInputModule,
    NzSelectModule,
    NzButtonModule,
    NzInputNumberModule,
    NzIntlTelInputModule,
    CodeInputModule,
    TranslateModule,
  ],
  exports: [SignupPhoneVerifyComponent],
})
export class SignupPhoneVerifyModule {}
