export interface LoginRequest {
  login: string;
  password: string;
}
export interface LoginData {
  token?: string;
  accountId?: string;
  accountType?: AccountType;
  email?: string;
  accountStatuses: Array<AccountStatus>;
  adminOverrideMode: boolean;
}
export interface LoginDataDTO {
  auth_token: string;
  user_state: UserStateDTO;
}

export interface UserStateDTO {
  accountId: {
    internalId: string;
  };
  email: string;
  accountType: AccountType;
  accountStatuses: Array<AccountStatus>;
  adminOverrideMode: boolean;
}

export enum AccountType {
  USER = "USER",
  MISSION_PARTNER = "MISSION_PARTNER",
  TALENT = "TALENT",
  ADMIN = "ADMIN",
}

export enum AccountStatus {
  REGISTERED = "REGISTERED",
  CONFIRMED = "CONFIRMED",
  VERIFIED = "VERIFIED",
  VACATIONING = "VACATIONING",
  REVIEWED = "REVIEWED",
  MARKED_FOR_DELETION = "MARKED_FOR_DELETION",
  SELF_SERVICE = "SELF_SERVICE",
}
