<h1 class="auth__title" nz-col nzSpan="24">{{ "reg.mp.company-contacts.title" | translate }}</h1>

<form
  nz-form
  class="auth__form"
  nzLayout="vertical"
  [nzAutoTips]="autoTips"
  [formGroup]="formGroup"
  (ngSubmit)="submitForm()"
>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <mh-location-input
        [showLabels]="true"
        nzSize="large"
        formControlName="address"
        [validators]="formGroup.get('address')?.validator"
        [mark]="formGroup.get('address')?.dirty || false"
      ></mh-location-input>
    </div>

    <div nz-col [nzSpan]="24">
      <div nz-row [nzGutter]="12">
        <div nz-col [nzSpan]="16" [nzSm]="18">
          <nz-form-item>
            <nz-form-label nzRequired>{{ "reg.mp.street-name.label" | translate }}</nz-form-label>
            <nz-form-control>
              <input nz-input nzSize="large" formControlName="street" [placeholder]="'input.placeholder' | translate" />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="8" [nzSm]="6">
          <nz-form-item>
            <nz-form-label nzRequired>{{ "reg.mp.street-number.label" | translate }}</nz-form-label>
            <nz-form-control>
              <input
                nz-input
                nzSize="large"
                formControlName="houseNumber"
                [placeholder]="'input.placeholder' | translate"
              />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
  </div>
  <ng-container formGroupName="contactPerson">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label nzRequired>{{ "reg.mp.contact-title.label" | translate }}</nz-form-label>
        <nz-form-control>
          <input nz-input nzSize="large" formControlName="title" [placeholder]="'input.placeholder' | translate" />
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-row [nzGutter]="24">
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label nzRequired>{{ "reg.mp.firstname.label" | translate }}</nz-form-label>
          <nz-form-control>
            <input
              nz-input
              nzSize="large"
              formControlName="firstName"
              [placeholder]="'reg.mp.firstname.placeholder' | translate"
            />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label nzRequired>{{ "reg.mp.lastname.label" | translate }}</nz-form-label>
          <nz-form-control>
            <input
              nz-input
              nzSize="large"
              formControlName="lastName"
              [placeholder]="'reg.mp.lastname.placeholder' | translate"
            />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div nz-row [nzGutter]="24">
      <div nz-col [nzSpan]="24">
        <nz-form-item>
          <nz-form-label nzRequired>{{ "reg.mp.department.label" | translate }}</nz-form-label>
          <nz-form-control>
            <input
              nz-input
              nzSize="large"
              formControlName="department"
              [placeholder]="'input.placeholder' | translate"
            />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="24">
        <nz-form-item>
          <nz-form-label [nzSpan]="24" [nzLabelAlign]="'left'" nzFor="phoneNumber">
            {{ "phone-number.label" | translate }}
          </nz-form-label>
          <nz-form-control
            [nzSpan]="24"
            [nzValidateStatus]="formGroup.controls['phoneNumber']"
            [nzErrorTip]="'phone-number.invalid' | translate"
          >
            <mh-nz-intl-tel-input
              [preferredCountries]="['de', 'fr']"
              [enablePlaceholder]="true"
              [enableSearch]="true"
              name="phoneNumber"
              describedBy="phoneInput"
              formControlName="phoneNumber"
              id="phoneNumber"
            ></mh-nz-intl-tel-input>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </ng-container>
</form>

<div nz-row [nzGutter]="24">
  <div nz-col [nzSpan]="24">
    <nz-form-item>
      <p>{{ "reg.required.explanation" | translate }}</p>
      <nz-form-control [nzSpan]="24">
        <button nz-button nzType="primary" nzSize="large" nzBlock [disabled]="loading" (click)="submitForm()">
          {{ "next-step.button" | translate }}
        </button>
      </nz-form-control>
    </nz-form-item>
  </div>
</div>
