import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TalentMissionPartnersComponent } from "./talent-mission-partners.component";
import { TranslateModule } from "@ngx-translate/core";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzIconModule } from "ng-zorro-antd/icon";
import { RouterLink } from "@angular/router";
import {
  CreateConversationDialogModule
} from "../../../../common-componnets/create-conversation-dialog/create-conversation-dialog.module";

@NgModule({
  declarations: [TalentMissionPartnersComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NzPageHeaderModule,
    NzAvatarModule,
    NzTableModule,
    NzButtonModule,
    NzIconModule,
    RouterLink,
    CreateConversationDialogModule,
  ],
})
export class TalentMissionPartnersModule {}
