<h1 class="auth__title" nz-col nzSpan="24">{{'reg.talent.job-prefs.title' | translate}}</h1>
<form nz-form class="auth__form" nzLayout="vertical" [nzAutoTips]="autoTips" [formGroup]="formGroup" (ngSubmit)="submitForm()">
  <ng-container>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="12">
      <ng-container formGroupName="workExperience">
        <nz-form-item>
          <nz-form-label [nzSpan]="24" [nzLabelAlign]="'left'" nzRequired>
            <mh-explanation-label [labelKey]="'reg.talent.work-exp.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control [nzSpan]="24" [nzErrorTip]="workExpErrorTpl">
            <nz-input-number nzSize="large" [nzMin]="1" [nzMax]="30" [nzStep]="1" nzInputMode="numeric" (keypress)="numberOnly($event)"
                             (change)="onExperienceChange()"
                             formControlName="yearsOfExperience"></nz-input-number>
          </nz-form-control>
          <ng-template #workExpErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">
              {{'field.required.label' | translate}}
            </ng-container>
          </ng-template>
        </nz-form-item>
      </ng-container>
    </div>
  </div>

  <nz-form-item>
    <nz-form-label [nzSpan]="24" [nzLabelAlign]="'left'" nzRequired>
      <mh-explanation-label [labelKey]="'reg.talent.reason-why.label'"></mh-explanation-label>
    </nz-form-label>
    <nz-form-control [nzSpan]="24">
      <nz-radio-group nzSize="large" formControlName="reasonWhy">
        <label nz-radio *ngFor="let option of (reasonWhy$ | async)"
               [nzValue]="option"
               [ngModel]="option.value == reasonWhyControl?.value?.value"
               [ngModelOptions]="{standalone: true}"
        >
          <span [innerHTML]="option.name | translate"></span>
        </label>
      </nz-radio-group>
    </nz-form-control>
  </nz-form-item>

  <div nz-row>
    <div nz-col [nzSpan]="24">
      <nz-form-label nzRequired>
        <mh-explanation-label [labelKey]="'profile.salary-expectation.label'"></mh-explanation-label>
      </nz-form-label>
      <mh-salary-expectation-input
        nzSize="large"
        formControlName="salaryExpectations"
        [validators]="formGroup.get('salaryExpectations')?.validator"
        [mark]="formGroup.get('salaryExpectations')?.dirty || false"
      ></mh-salary-expectation-input>
    </div>
  </div>

  <div nz-row [nzGutter]="12">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-control nzDisableAutoTips>
          <mh-location-input
            [showLabels]="true"
            nzSize="large"
            formControlName="contactData"
            [validators]="formGroup.get('contactData')?.validator"
            [mark]="formGroup.get('contactData')?.dirty || false"
          ></mh-location-input>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div nz-col [nzSpan]="24">
    <nz-form-item>
      <nz-form-label nzRequired>{{'reg.talent.profile-link.label' | translate}}</nz-form-label>
      <nz-form-control [nzErrorTip]="urlErrorTpl">
        <input nz-input nzSize="large" [placeholder]="'reg.talent.profile-link.placeholder' | translate" formControlName="profileLink"/>
      </nz-form-control>
      <ng-template #urlErrorTpl let-control>
        <ng-container *ngIf="control.hasError('url')">{{'field.invalid-url.label' | translate}}</ng-container>
      </ng-template>
    </nz-form-item>
  </div>
  </ng-container>
</form>
<form nz-form class="auth__form" nzLayout="vertical" [nzAutoTips]="autoTips" [formGroup]="knownFromFormGroup" (ngSubmit)="submitForm()">
    <div nz-row [nzGutter]="12">
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label>{{'reg.talent.know-from.label' | translate}}</nz-form-label>
          <nz-form-control>
            <nz-select nzSize="large" formControlName="knownFrom"
                       [compareWith]="compareCmsNameValue">
              <nz-option [nzLabel]="option.name | translate" [nzValue]="option" *ngFor="let option of (knownFrom$ | async)"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="12">
        <nz-form-item *ngIf="showKnownFromDetailsControl">
          <nz-form-label>{{'reg.talent.know-from-details.label' | translate}}</nz-form-label>
          <nz-form-control>
            <input nz-input nzSize="large" formControlName="knownFromText"/>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
</form>
<div nz-row [nzGutter]="24">
  <div nz-col [nzSpan]="24">

    <nz-form-item>
      <p>{{'reg.required.explanation' | translate}}</p>
      <nz-form-control [nzSpan]="24">
        <button nz-button nzType="primary" nzSize="large" nzBlock
                [disabled]="loading"
                (click)="submitForm()">{{'next-step.button' | translate}}</button>
      </nz-form-control>
    </nz-form-item>
  </div>
</div>
