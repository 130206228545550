import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { map, Observable, Subject, takeUntil, tap } from "rxjs";
import { Store } from "@ngrx/store";
import { jobOfferFeature, JobOfferState } from "./job-offer-data/store/job-offer.reducer";
import { isLoadingJobOfferList, selectJobOfferList } from "./job-offer-data/store/job-offer.selectors";
import { JobOffer } from "../../../../api/job-offer/job-offer.types";
import { JobOfferAction } from "./job-offer-data/store/job-offer.action";
import { TranslateService } from "@ngx-translate/core";
import { NzModalService } from "ng-zorro-antd/modal";
import { MhNotificationType, MhSocketMessage } from "../../../../api/notification/notification.types";
import { SocketService } from "../../../../../../../core/src/lib/soket/socket.service";
import { BreakpointObserver } from "@angular/cdk/layout";
import { AuthorizationService } from "../../../../api/authorization/authorization.service";
import { AccountStatus, LoginData } from "../../../login-page/login-page-data/login.types";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "mh-mission-partner-job-offers",
  templateUrl: "./mission-partner-job-offers.component.html",
  styleUrls: ["./mission-partner-job-offers.component.less"],
})
export class MissionPartnerJobOffersComponent implements OnInit, OnDestroy {
  isLoading$: Observable<boolean>;
  jobOfferList$: Observable<Array<JobOffer>>;
  hasReviewedStatus$: Observable<boolean>;
  hasSelfServiceStatus$: Observable<boolean>;
  jobOfferListActive: Array<JobOffer> = [];
  jobOfferListArchived: Array<JobOffer> = [];
  private readonly destroy$ = new Subject<void>();
  isMobileView$: Observable<boolean>;

  constructor(
    private readonly store: Store<{ [jobOfferFeature]: JobOfferState }>,
    private translateService: TranslateService,
    private modal: NzModalService,
    private socketService: SocketService,
    private authorizationService: AuthorizationService,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
  ) {
    this.isLoading$ = this.store.select(isLoadingJobOfferList);
    this.jobOfferList$ = this.store.select(selectJobOfferList).pipe(
      tap((jobOffers) => {
        this.jobOfferListActive = jobOffers.filter((jo) => !jo.archived);
        this.jobOfferListArchived = jobOffers.filter((jo) => jo.archived);
      }),
    );
    this.hasReviewedStatus$ = this.authorizationService.hasReviewedStatus().pipe(takeUntil(this.destroy$));
    this.hasSelfServiceStatus$ = this.authorizationService.hasSelfServiceStatus().pipe(takeUntil(this.destroy$));
    this.socketService.messages$.pipe(takeUntil(this.destroy$)).subscribe((message: MhSocketMessage) => {
      if (message.action === MhNotificationType.TALENTS_SEARCH_STARTED && message.jobOpportunityId) {
        this.store.dispatch(JobOfferAction.talentMatchSearchStarted({ jobOfferId: message.jobOpportunityId }));
      }
      if (message.action === MhNotificationType.TALENTS_SEARCH_FINISHED && message.jobOpportunityId) {
        this.store.dispatch(JobOfferAction.talentMatchSearchSuccess({ jobOfferId: message.jobOpportunityId }));
      }
    });

    this.isMobileView$ = this.breakpointObserver.observe(["(max-width: 767px)"]).pipe(
      map((breakpoint) => breakpoint.matches),
      tap((mobileView) => {
        this.changeDetectorRef.detectChanges();
      }),
    );
  }

  ngOnInit() {
    this.store.dispatch(JobOfferAction.loadJobOfferList());
  }

  onUnArchiveJobOffer(entity: JobOffer) {
    //this.showArchiveConfirm(entity);
    this.store.dispatch(JobOfferAction.unArchiveJobOffer({ entity }));
  }

  onArchiveJobOffer(entity: JobOffer) {
    this.showArchiveConfirm(entity);
  }

  showArchiveConfirm(entity: JobOffer) {
    this.modal.confirm({
      nzTitle: this.translateService.instant("admin.jo.archive.confirm.title"),
      nzContent: `<b>${this.translateService.instant("admin.jo.archive.confirm.text")}</b>`,
      nzOkText: this.translateService.instant("ok.button"),
      nzCancelText: this.translateService.instant("cancel.button"),
      nzOkType: "primary",
      nzOkDanger: true,
      nzOnOk: () => this.archiveJobOffer(entity),
    });
  }

  archiveJobOffer(entity: JobOffer) {
    this.store.dispatch(JobOfferAction.archiveJobOffer({ entity }));
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
