<ng-container [ngTemplateOutlet]="(isMobileView$ | async) ? mobileView : desktopView"></ng-container>
<ng-template #desktopView>
  <div nz-col [nzSpan]="24">
    <nz-table
      class="matches-list conversation-list-table"
      nzSize="small"
      nzShowSizeChanger="true"
      nzFrontPagination="false"
      [nzShowTotal]="rangeTemplate"
      [nzData]="conversations.items"
      [nzLoading]="loading"
      [nzTotal]="conversations?.paging?.items || 0"
      [nzPageSize]="conversations?.paging?.itemsOnPage || 0"
      [nzPageIndex]="conversations?.paging?.page || 0"
      (nzQueryParams)="onQueryParamsChange($event)"
    >
      <thead>
        <tr>
          <th [nzWidth]="'300px'">{{ "inbox.contact.label" | translate }}</th>
          <th>{{ "inbox.latest-message.label" | translate }}</th>
          <th [nzWidth]="'200px'">{{ "inbox.conversation-time.label" | translate }}</th>
          <th [nzWidth]="'60px'">{{ "inbox.actions.label" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let conversation of conversations.items"
          (click)="onConversationClick(conversation)"
          [class.conversation-ended]="
            conversation.status === ConversationStatus.ENDED ||
            conversation.status === ConversationStatus.OTHER_SIDE_DELETED
          "
        >
          <td>
            <div class="cell-content">
              <div *ngIf="conversation.recipientInfo" class="contact">
                <nz-avatar
                  class="mh-avatar"
                  [class.mh-avatar--logo]="loginData.accountType === AccountType.TALENT"
                  [nzSrc]="conversation.recipientInfo.avatarUrl"
                  [nzSize]="60"
                ></nz-avatar>
                <div class="contact-name">
                  <ng-container *ngIf="conversation.recipientInfo.companyName"
                    ><b>{{ conversation.recipientInfo.companyName }}</b
                    ><br
                  /></ng-container>
                  {{ conversation.recipientInfo.name }}
                </div>
              </div>
            </div>
          </td>
          <td>
            <div class="cell-content">
              {{ conversation.title | conversationTranslate : conversation.recipientInfo | async }}<br />
              <div class="message-preview">
                {{ conversation.lastMessageSenderKey ? (conversation.lastMessageSenderKey | translate) + ":" : "" }}
                {{ conversation.latestMessagePreview | conversationTranslate : conversation.recipientInfo | async }}
              </div>
            </div>
          </td>
          <td>
            <div class="cell-content">
              <div class="table small">
                <div class="tr">
                  <div class="td label">{{ "updated.label" | translate }}:</div>
                  <div class="td value">{{ conversation.lastMessageTimestamp | date : "dd.MM.YYYY HH:mm" }}</div>
                </div>
                <div class="tr">
                  <div class="td label">{{ "started.label" | translate }}:</div>
                  <div class="td value">{{ conversation.creationTimestamp | date : "dd.MM.YYYY" }}</div>
                </div>
              </div>
            </div>
          </td>
          <td>
            <div class="cell-content">
              <nz-badge
                [nzCount]="conversation.numberOfUnreadMessages"
                [nzOverflowCount]="9"
                nzSize="small"
                [nzOffset]="[-5, 5]"
              >
                <button
                  nz-button
                  nzType="link"
                  nzSize="small"
                  [nz-tooltip]="'inbox.to-conversation.tooltip' | translate"
                  (click)="onConversationClick(conversation)"
                >
                  <span nz-icon nzType="message" nzTheme="outline"></span>
                </button>
              </nz-badge>
              <button
                *ngIf="conversation.status !== ConversationStatus.ENDED"
                nz-button
                nzType="link"
                nzSize="small"
                [nz-tooltip]="'inbox.close-conversation.tooltip' | translate"
                (click)="onConversationClose($event, conversation)"
              >
                <span nz-icon nzType="close" nzTheme="outline"></span>
              </button>
              <button
                *ngIf="conversation.status === ConversationStatus.ENDED"
                nz-button
                nzType="link"
                nzSize="small"
                [nz-tooltip]="'inbox.delete-conversation.tooltip' | translate"
                (click)="onConversationDelete($event, conversation)"
              >
                <span nz-icon nzType="delete" nzTheme="outline"></span>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
  <ng-template #rangeTemplate let-range="range" let-total>
    {{ range[0] }}-{{ range[1] }} {{ "of.label" | translate }} {{ total }} items
  </ng-template>
</ng-template>

<ng-template #mobileView>
  <div nz-col [nzSpan]="24">
    <nz-table
      class="matches-list conversation-list-table-mobile"
      nzSize="small"
      nzShowSizeChanger="true"
      [nzShowPagination]="(conversations?.paging?.items || 0) > (conversations?.paging?.itemsOnPage || 0)"
      nzFrontPagination="false"
      [nzShowTotal]="rangeTemplateMobile"
      [nzData]="conversations.items"
      [nzLoading]="loading"
      [nzTotal]="conversations?.paging?.items || 0"
      [nzPageSize]="conversations?.paging?.itemsOnPage || 0"
      [nzPageIndex]="conversations?.paging?.page || 0"
      (nzQueryParams)="onQueryParamsChange($event)"
    >
      <thead>
        <tr>
          <th [nzWidth]="'60px'"></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let conversation of conversations.items"
          (click)="onConversationClick(conversation)"
          [class.conversation-ended]="
            conversation.status === ConversationStatus.ENDED ||
            conversation.status === ConversationStatus.OTHER_SIDE_DELETED
          "
        >
          <td class="td-conversation-avatar">
            <div class="cell-content">
              <div *ngIf="conversation.recipientInfo" class="contact">
                <nz-avatar
                  class="mh-avatar"
                  [class.mh-avatar--logo]="loginData.accountType === AccountType.TALENT"
                  [nzSrc]="conversation.recipientInfo.avatarUrl"
                  [nzSize]="60"
                ></nz-avatar>
              </div>
              <div class="conversation-status">
                <div class="conversation-status-icon">
                  <span
                    *ngIf="conversation.status === ConversationStatus.INITIATED"
                    nz-icon
                    nzType="eye-invisible"
                    nzTheme="outline"
                  ></span>
                  <span
                    *ngIf="conversation.status === ConversationStatus.AGREEMENT_REACHED_ONE_SIDED"
                    nz-icon
                    nzType="eye-invisible"
                    nzTheme="outline"
                  ></span>
                  <span
                    *ngIf="conversation.status === ConversationStatus.ACTIVE"
                    nz-icon
                    nzType="message"
                    nzTheme="outline"
                  ></span>
                  <span
                    *ngIf="
                      conversation.status === ConversationStatus.COMPLETED ||
                      conversation.status === ConversationStatus.ENDED
                    "
                    nz-icon
                    nzType="check"
                    nzTheme="outline"
                  ></span>
                  <span
                    *ngIf="
                      conversation.status === ConversationStatus.OTHER_SIDE_DELETED ||
                      conversation.status === ConversationStatus.DELETED
                    "
                    nz-icon
                    nzType="close"
                    nzTheme="outline"
                  ></span>
                </div>
              </div>
            </div>
          </td>
          <td class="td-conversation-details">
            <div class="cell-content">
              <div class="conversation-cell">
                <div *ngIf="conversation.recipientInfo" class="contact">
                  <div class="contact-name">
                    {{ conversation.recipientInfo.name }}
                    <ng-container *ngIf="conversation.recipientInfo.companyName"
                      ><span class="contact-company">{{ conversation.recipientInfo.companyName }}</span></ng-container
                    >
                    <span class="contact-company">{{
                      conversation.title | conversationTranslate : conversation.recipientInfo | async
                    }}</span>
                  </div>
                  <div class="contact-time">
                    <div class="label">{{ conversation.lastMessageTimestamp | date : "dd.MM.YYYY" }}</div>
                    <div class="value">{{ conversation.lastMessageTimestamp | date : "HH:mm" }}</div>
                  </div>
                </div>
                <div class="message-preview">
                  {{ conversation.lastMessageSenderKey ? (conversation.lastMessageSenderKey | translate) + ":" : "" }}
                  {{ conversation.latestMessagePreview | conversationTranslate : conversation.recipientInfo | async }}
                </div>
              </div>

              <div class="conversation-message-counter">
                <nz-badge nzStandalone [nzCount]="conversation.numberOfUnreadMessages"></nz-badge>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
  <ng-template #rangeTemplateMobile let-range="range" let-total>
    {{ range[0] }}-{{ range[1] }} {{ "of.label" | translate }} {{ total }} items
  </ng-template>
</ng-template>
