import { Component, Inject } from "@angular/core";
import { TalentAccount } from "../../../../platform-pages/src/lib/pages/home-admin/home-admin-data/account.types";
import { NZ_MODAL_DATA } from "ng-zorro-antd/modal";

@Component({
  selector: "mh-account-password-dialog",
  templateUrl: "./account-comments-dialog.component.html",
  styleUrls: ["./account-comments-dialog.component.less"],
})
export class AccountCommentsDialogComponent {
  account: TalentAccount;

  constructor(@Inject(NZ_MODAL_DATA) public modalData: any) {
    this.account = this.modalData["account"];
  }
}
