<div class="explanation-label">
  <span #customContent class="explanation-label__content custom-content">
    <ng-content></ng-content>
  </span>

  <ng-container *ngIf="!divider">
    <span *ngIf="!customContent.hasChildNodes()" class="explanation-label__content">{{labelKey | translate}}</span>
    <button *ngIf="hasExplanation" nz-button nzSize="small" nzShape="circle" nzType="link" class="explanation-label__icon" tabIndex="-1">
      <span
        nz-icon
        nzType="question-circle"
        nzTheme="outline"
        nz-popover
        nzPopoverPlacement="top"
        nzPopoverOverlayClassName="explanation-popover"
        [nzPopoverContent]="explanationKey | translate"
      ></span>
    </button>
  </ng-container>

  <ng-container *ngIf="divider">
    <nz-divider nzPlain [nzText]="text" nzOrientation="left">
      <ng-template #text>
        <span>{{labelKey | translate}}</span>
        <button *ngIf="hasExplanation" nz-button nzSize="small" nzShape="circle" nzType="link" class="explanation-label__icon" tabIndex="-1">
          <span
            nz-icon
            nzType="question-circle"
            nzTheme="outline"
            nz-popover
            nzPopoverPlacement="top"
            nzPopoverOverlayClassName="explanation-popover"
            [nzPopoverContent]="explanationKey | translate"
          ></span>
        </button>
      </ng-template>
    </nz-divider>
  </ng-container>
</div>
