<nz-page-header class="large p-l-8 p-r-0">
  <nz-page-header-title>{{'mp-list.title' | translate}}</nz-page-header-title>
</nz-page-header>

<nz-table
  class="mission-partner-list"
  nzSize="small"
  nzShowSizeChanger="true"
  nzFrontPagination="false"
  [nzShowTotal]="rangeTemplate"
  [nzData]="missionPartnerList?.items || []"
  [nzLoading]="loading"
  [nzTotal]="missionPartnerList?.paging?.items || 0"
  [nzPageSize]="missionPartnerList?.paging?.itemsOnPage || 0"
  [nzPageIndex]="missionPartnerList?.paging?.page || 0"
  (nzQueryParams)="onQueryParamsChange($event)"
>
  <thead>
  <tr>
    <th [nzWidth]="'30px'">{{'mp-list.number.title' | translate}}</th>
    <th>{{'mp-list.mission-partner.title' | translate}}</th>
    <th [nzWidth]="(isMobileView$ | async) ? '32px' : '155px'"></th>
  </tr>
  </thead>
  <tbody>
  <tr
    *ngFor="let missionPartner of missionPartnerList?.items; let index = index"
    [routerLink]="['/talent/view-micro-site/', missionPartner.accountId?.internalId]"
    class="contact-item"
  >
    <td>
      {{calculateNumber(index)}}
    </td>
    <td>
      <div class="contact">
        <nz-avatar class="mh-avatar mh-avatar--logo" [nzSrc]="missionPartner?.avatarUrl" [nzSize]="60"></nz-avatar>
        <div class="contact-name">
          <ng-container *ngIf="missionPartner.companyName">
            <b>{{missionPartner.companyName}}</b><br/>
          </ng-container>
          <span class="comma-separated">{{missionPartner.address?.city}}</span> <span class="comma-separated">{{missionPartner.address?.country}}</span>
        </div>
      </div>
    </td>
    <td>
      <button
        *ngIf="missionPartner.conversationId"
        class="send-button"
        nz-button
        nzType="primary"
        type="button"
        [routerLink]="['/talent/inbox/conversation/', missionPartner.conversationId]"
        [queryParams]="{origin: 'talent-mission-partners'}"
        (click)="stopPropagation($event)"
      >
        <span *ngIf="(isMobileView$ | async) === false">{{'mp-list.send-btn' | translate}}</span>
        <span *ngIf="(isMobileView$ | async)" nz-icon nzType="send"></span>
      </button>
      <button
        *ngIf="!missionPartner.conversationId"
        class="send-button"
        nz-button
        nzType="primary"
        type="button"
        (click)="openCreateConversationModal($event, missionPartner)"
      >
        <span *ngIf="(isMobileView$ | async) === false">{{'mp-list.start-conversation-btn' | translate}}</span>
        <span *ngIf="(isMobileView$ | async)" nz-icon nzType="send"></span>
      </button>
    </td>
  </tr>
  </tbody>
</nz-table>

<ng-template #rangeTemplate let-range="range" let-total>
  {{ range[0] }}-{{ range[1] }} {{'of.label' | translate}} {{ total }} items
</ng-template>
