import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { CmsCachedService } from "../../../../api/cms/cms-cached.service";
import { Observable, Subject, takeUntil } from "rxjs";
import { CmsNameValue, compareCmsNameValue } from "../../../../api/cms/cms.types";
import { Talent } from "../../../../api/sign-up/signup.types";
import { UrlValidator } from "@momhunting/core";
import { TranslateService } from "@ngx-translate/core";
import { ProfileService } from "../../../../api/profile/profile.service";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { flattenControls } from "@momhunting/common-ui";

@Component({
  selector: "mh-signup-talent-job-preferences",
  templateUrl: "./signup-talent-job-preferences.component.html",
  styleUrls: ["./signup-talent-job-preferences.component.less"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SignupTalentJobPreferencesComponent implements OnInit, OnDestroy {
  @Input() talent!: Talent;
  @Input() loading = false;
  @Output() formChange = new EventEmitter<Talent>();
  @Output() updateUser = new EventEmitter<void>();

  public formGroup!: FormGroup;
  public knownFromFormGroup!: FormGroup;
  private readonly destroy$ = new Subject<void>();
  public reasonWhy$: Observable<Array<CmsNameValue>>;
  public knownFrom$: Observable<Array<CmsNameValue>>;

  autoTips: Record<string, Record<string, string>> = {
    default: {
      required: this.translateService.instant("field.required.label"),
      invalid: this.translateService.instant("field.invalid.label"),
    },
  };

  get showKnownFromDetailsControl(): boolean {
    const knownFromControl = this.knownFromFormGroup.get("knownFrom");
    return [4, 6, 9, 11].includes((knownFromControl?.value as CmsNameValue)?.value);
  }

  get reasonWhyControl() {
    return this.formGroup.get("reasonWhy");
  }

  constructor(
    private cmsCachedService: CmsCachedService,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private profileService: ProfileService,
    private notificationService: NzNotificationService,
  ) {
    this.reasonWhy$ = this.cmsCachedService.getUserDataByName("reason-why", false);
    this.knownFrom$ = this.cmsCachedService.getUserDataByName("known-from");
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      workExperience: this.fb.group({
        yearsOfExperience: [this.talent.workExperience?.yearsOfExperience, [Validators.required]],
      }),
      reasonWhy: [this.talent.reasonWhy, [Validators.required]],
      salaryExpectations: [this.talent.salaryExpectations || {min: null, max: null}, [Validators.required]],
      contactData: [this.talent.contactData, [Validators.required, Validators.minLength(4)]],
      profileLink: [this.talent.profileLink, [Validators.required, UrlValidator]],
    });

    this.knownFromFormGroup = this.fb.group({
      knownFrom: [undefined, []],
      knownFromText: [undefined, []],
    });

    this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (this.formGroup.valid) this.formChange.emit(value);
    });
  }

  submitForm() {
    if (this.formGroup.invalid) {
      flattenControls(this.formGroup).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return;
    }
    this.profileService.saveKnownFrom(this.knownFromFormGroup.value).subscribe((success) => this.updateUser.emit());
  }

  numberOnly(event: KeyboardEvent): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  onExperienceChange() {
    if (this.getYearsOfExperienceControl()?.value >= 30) {
      this.notificationService.warning("", this.translateService.instant("max-allowed-years.warning", { max: 30 }));
    }
  }

  getYearsOfExperienceControl(): AbstractControl | null {
    return this.formGroup.controls["workExperience"]?.get("yearsOfExperience");
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  protected readonly compareCmsNameValue = compareCmsNameValue;
  protected readonly Validators = Validators;
}
