import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AdminJobOfferListComponent } from "./admin-job-offer-list.component";
import { ReactiveFormsModule } from "@angular/forms";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzInputModule } from "ng-zorro-antd/input";
import { TranslateModule } from "@ngx-translate/core";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { RouterLink } from "@angular/router";
import { AdminJobOfferEditModule } from "./admin-job-offer-edit/admin-job-offer-edit.module";
import { TagsOutputModule } from "../../../../../../../common-ui/src/lib/tags-output/tags-output.module";
import { AdminJobOfferCreateModule } from "./admin-job-offer-create/admin-job-offer-create.module";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzSelectModule } from "ng-zorro-antd/select";
import {NzSpinModule} from "ng-zorro-antd/spin";

@NgModule({
  declarations: [AdminJobOfferListComponent],
    imports: [
        CommonModule,
        TranslateModule,
        AdminJobOfferEditModule,
        AdminJobOfferCreateModule,
        NzTableModule,
        NzDividerModule,
        NzFormModule,
        NzInputModule,
        NzIconModule,
        ReactiveFormsModule,
        NzToolTipModule,
        NzButtonModule,
        RouterLink,
        TagsOutputModule,
        NzDatePickerModule,
        NzCheckboxModule,
        NzSelectModule,
        NzSpinModule,
    ],
})
export class AdminJobOfferListModule {}
