import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { AdminMissionPartnerListComponent } from "./admin-mission-partner-list.component";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzIconModule } from "ng-zorro-antd/icon";
import { ReactiveFormsModule } from "@angular/forms";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzButtonModule } from "ng-zorro-antd/button";
import { RouterLink } from "@angular/router";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzSelectModule } from "ng-zorro-antd/select";
import { AccountPasswordDialogModule } from "../../../../../../../common-ui/src/lib/account-password-dialog/account-password-dialog.module";

@NgModule({
  declarations: [AdminMissionPartnerListComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NzTableModule,
    NzDividerModule,
    NzFormModule,
    NzInputModule,
    NzIconModule,
    ReactiveFormsModule,
    NzToolTipModule,
    NzButtonModule,
    RouterLink,
    NzDatePickerModule,
    NzSelectModule,
    AccountPasswordDialogModule,
  ],
})
export class AdminMissionPartnerListModule {}
