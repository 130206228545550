import { Injectable } from "@angular/core";
import { MissionPartner } from "../sign-up/signup.types";

@Injectable({ providedIn: "root" })
export class MissionPartnerProfileFactory {
  getNewInstance(): MissionPartner {
    return {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      companyName: "",
      sector: undefined,
      presentationMedia: null,
      updateTimestamp: 0,
      complianceKpi: {
        kpiPossibleWorkingAssignments: [],
        kpiPercentageFemaleLeadership: 0,
        kpiPercentagePartTimeLeadership: 0,
        kpiQuestion1: "",
        kpiQuestion2: "",
        kpiQuestion3: "",
        kpiQuestion4: "",
        kpiQuestion5: "",
        kpiQuestion6: "",
        kpiQuestion7: "",
        kpiQuestion8: "",
        kpiQuestion9: "",
        kpiQuestion10: "",
      },
      diversities: {
        diversity1: "",
        diversity2: "",
        diversity3: "",
        diversity4: "",
        diversity5: "",
        diversity6: "",
        diversity7: "",
        diversity8: "",
        diversity9: "",
        diversity10: "",
      },
      hashtags: [],
    };
  }
}
